import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import get from "lodash/get";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";

const bioFields = [
  { field: "shortBody", title: "Summary" },
  { field: "body", title: "Profile" },
  { field: "education", title: "Education" },
  { field: "barAdmissions", title: "Bar and Court Admissions" },
  { field: "professionalAffiliations", title: "Professional Affiliations" },
  { field: "professionalAwards", title: "Professional Awards" },
  { field: "publications", title: "Publications" },
  { field: "communityInvolvement", title: "Community Involvement" },
];

class AttorneyBioTemplate extends React.Component {
  render() {
    const attorney = get(this.props, "data.contentfulAttorney");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${attorney.fullName}`}
          description={`${attorney.fullName} is a business attorney in San Antonio, Texas.`}
          keywords={`${attorney.fullName},attorney,business attorney,injury lawyer`}
        />

        <section className="m-3 mt-5">
          <div className="columns">
            <div className="column is-one-third">
              <div className="has-text-centered p-2">
                <figure className="image">
                  <GatsbyImage
                    image={getImage(attorney.photo)}
                    alt={attorney.photo.title}
                    width={attorney.photo.gatsbyImageData.width}
                    height={attorney.photo.gatsbyImageData.height}
                  />
                </figure>

                {(attorney.badges === null ? [] : attorney.badges).map(
                  (image) => {
                    return (
                      <figure className="image mt-3">
                        <GatsbyImage
                          image={getImage(image)}
                          alt={image.title}
                          width={image.gatsbyImageData.width}
                          height={image.gatsbyImageData.height}
                        />
                      </figure>
                    );
                  }
                )}
              </div>
            </div>
            <div className="column">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/lawyers/">Lawyers</Link>
                  </li>
                  <li class="is-active">
                    <Link to="#" aria-current="page">
                      {attorney.fullName}
                    </Link>
                  </li>
                </ul>
              </nav>
              <div class="box content">
                <h1 className="title my-2">{attorney.fullName}</h1>
                <p className="my-1 has-text-weight-semibold">
                  {attorney.title}
                </p>
                <hr className="my-2 has-background-grey-light" />
                210-223-2627 |{" "}
                <a
                  href={`mailto:${attorney.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Send an email
                </a>
                {bioFields.map((section, index) => {
                  if (attorney[section.field] === null) {
                    return "";
                  }
                  return (
                    <>
                      <h2 className="subtitle is-4">{section.title}</h2>
                      <div className="content">
                        {renderRichText(attorney[section.field])}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default AttorneyBioTemplate;

export const pageQuery = graphql`
  query AttorneyPostBySlug($slug: String!) {
    contentfulAttorney(slug: { eq: $slug }) {
      fullName
      firstName
      title
      texasBarNumber
      email
      slug
      startDate(formatString: "MMMM Do, YYYY")
      photo {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 350
          resizingBehavior: SCALE
          placeholder: TRACED_SVG
        )
      }
      headerImage {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      badges {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 200
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      body {
        raw
      }
      shortBody {
        raw
      }
      education {
        raw
      }
      barAdmissions {
        raw
      }
      professionalAwards {
        raw
      }
      professionalAffiliations {
        raw
      }
      publications {
        raw
      }
      communityInvolvement {
        raw
      }
    }
  }
`;
